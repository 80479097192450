import React, {Component} from 'react';
import {Helmet} from 'react-helmet'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import {getCartTotal} from "../../services";
import {closeCart, decrementQty, incrementQty, openCart, removeFromCart, retrieveCart} from '../../actions'
import {number_format} from "../../helpers/stringHelper";
import {empty} from "../../helpers/generalHelper";
import styles from './Cart.module.scss';
import {message} from "antd";
import {LOCAL_DEVICE_ID} from "../../utils/constants";
import {apiCall} from "../../helpers/networkHelper";
import {DECREMENT_CART_ITEM_QTY, INCREMENT_CART_ITEM_QTY, REMOVE_CART_ITEM} from "../../utils/endpoints";
import ErrorHandler from "../../classes/ErrorHandler";
import {Loader} from "react-overlay-loader";
import SHA1 from 'crypto-js/sha1';
import MD5 from 'crypto-js/md5';

class cartComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            pageLoading: false,
            loadingText: ""
        };
    }

    componentDidMount() {
        this.props.closeCart();
    }

    showLoader = (loadingText = 'Loading..') => {
        this.setState({
            pageLoading: true,
            loadingText
        });
    };

    hideLoader = () => {
        this.setState({
            pageLoading: false,
            loadingText: ''
        });
    };

    incrementQty = (item) => {
        this.showLoader('Increasing quantity..');
        const form_data = {
            local_device_id: LOCAL_DEVICE_ID(),
            product_detail_id: item.product_detail_id,
            size: item.size_code,
            qty: 1
        };

        apiCall(
            INCREMENT_CART_ITEM_QTY,
            'post',
            form_data,
            (res) => {
                message.success(res.data.message);
                this.props.retrieveCart();
            },
            (err) => {
                ErrorHandler.handleGeneralError(err);
            },
            (res) => {
                this.hideLoader();
            },
        );
    };

    decrementQty = (item) => {
        this.showLoader('Decreasing quantity..');
        const form_data = {
            local_device_id: LOCAL_DEVICE_ID(),
            product_detail_id: item.product_detail_id,
            size: item.size_code,
            qty: 1
        };

        apiCall(
            DECREMENT_CART_ITEM_QTY,
            'post',
            form_data,
            (res) => {
                message.success(res.data.message);
                this.props.retrieveCart();
            },
            (err) => {
                ErrorHandler.handleGeneralError(err);
            },
            (res) => {
                this.hideLoader();
            },
        );
    };

    removeItem = (item) => {
        if(window.confirm('Are you sure?')){
            this.showLoader('Removing item from cart..');
            const form_data = {
                local_device_id: LOCAL_DEVICE_ID(),
                product_detail_id: item.product_detail_id,
                size: item.size_code
            };

            apiCall(
                REMOVE_CART_ITEM,
                'delete',
                form_data,
                (res) => {
                    message.success(res.data.message);
                    this.props.retrieveCart();
                },
                (err) => {
                    ErrorHandler.handleGeneralError(err);
                },
                (res) => {
                    this.hideLoader();
                },
            );
        }
    };

    render() {
        const {onlineCart, cartItems, symbol} = this.props;
        const {pageLoading, loadingText} = this.state;
        let total = 0;
        let totalMobile = 0;

        return (
            <div>
                {/*SEO Support*/}
                <Helmet>
                    <title>Coconut Island | PT Warna Mardhika</title>
                    <meta name="description"
                          content="Multikart – Multipurpose eCommerce React Template is a multi-use React template. It is designed to go well with multi-purpose websites. Multikart Bootstrap 4 Template will help you run multiple businesses."/>
                </Helmet>
                {/*SEO Support End */}

                <Loader fullPage loading={pageLoading} text={loadingText}/>

                <h1 style={{
                    textAlign: 'center',
                    fontFamily: "'DM Sans', sans-serif",
                    marginTop: 50,
                    marginBottom: 0,
                    fontSize: '40px',
                    fontWeight: 700
                }}
                >My Cart</h1>

                {!empty(onlineCart) && !empty(onlineCart.cart_items) && (
                    <section className="cart-section section-b-space">
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-12">
                                    <table className={`table cart-table table-responsive-xs ${styles.cartTable}`}>
                                        <thead>
                                        <tr className="table-head">
                                            <th scope="col">&nbsp;</th>
                                            <th scope="col">product name</th>
                                            <th scope="col">Size</th>
                                            <th scope="col">price</th>
                                            <th scope="col">quantity</th>
                                            <th scope="col">total</th>
                                            <th scope="col">action</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {onlineCart.cart_items.map((item, index) => {
                                            const retailPrice = parseInt(item.product_detail.retail_price);
                                            const netPrice = parseInt(item.product_detail.net_price);
                                            // const directDiscPercent = !empty(item.product_detail.direct_discount)?parseFloat(item.product_detail.direct_discount.direct_disc_percent):0;
                                            // const directDiscNominal = !empty(item.product_detail.direct_discount)?parseInt(item.product_detail.direct_discount.direct_disc_nominal):0;
                                            // const grandPrice = (netPrice * ((100-directDiscPercent)/100)).toFixed(0) - directDiscNominal;
                                            const grandPrice = netPrice;

                                            let coverImage = item.product_detail.product_images[0]?.image_url;
                                            item.product_detail.product_images.find(productImage => {
                                                if(productImage.image_type === 'COVER'){
                                                    if(!empty(productImage.image_url)){
                                                        coverImage = productImage.image_url;
                                                    }
                                                }
                                            });

                                            total += grandPrice * item.qty;

                                            return (
                                                <React.Fragment key={index}>
                                                    <tr className={styles.desktopCartItem}>
                                                    <td>
                                                        <div className={styles.productThumb}>
                                                        <Link
                                                            to={`${process.env.PUBLIC_URL}/left-sidebar/product/${item.id}`}>
                                                            <img src={coverImage} alt=""/>
                                                        </Link>
                                                        </div>
                                                    </td>
                                                    <td><Link
                                                        to={`${process.env.PUBLIC_URL}/left-sidebar/product/${item.id}`}>{item.product_detail.product_name}</Link>
                                                        <div className="mobile-cart-content row">
                                                            <div className="col-xs-3">
                                                                <div className="qty-box">
                                                                    <div className="input-group">
                                                                        <input type="text" name="quantity"
                                                                               className="form-control input-number"
                                                                               defaultValue={item.qty}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-xs-3">
                                                                <h2 className="td-color">{symbol} {number_format(grandPrice, 0)}</h2>
                                                            </div>
                                                            <div className="col-xs-3">
                                                                <h2 className="td-color">
                                                                    <a href="#" className="icon"
                                                                       onClick={() => this.props.removeFromCart(item)}>
                                                                        <i className="icon-close"/>
                                                                    </a>
                                                                </h2>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td><h2 style={{
                                                        fontSize: 16
                                                    }}
                                                    >{item.size_name}</h2></td>

                                                    <td>
                                                        <h2 style={{
                                                            fontSize: 16
                                                        }}
                                                        >
                                                            {retailPrice !== grandPrice && (
                                                                <div style={{marginBottom: 5}}>
                                                                    <del><span className="money">{symbol} {number_format(retailPrice, 0)}</span></del>
                                                                </div>
                                                            )}
                                                            {symbol} {number_format(grandPrice, 0)}
                                                        </h2>
                                                    </td>

                                                    <td>
                                                        <div className="qty-box">
                                                            <div className="input-group">
                                                            <span className="input-group-prepend">
                                                                <button type="button"
                                                                        className="btn quantity-left-minus"
                                                                        onClick={() => this.decrementQty(item)}
                                                                        data-type="minus" data-field="">
                                                                 <i className="fa fa-angle-left"/>
                                                                </button>
                                                            </span>
                                                                <input type="text" name="quantity" value={item.qty}
                                                                       readOnly={true}
                                                                       className="form-control input-number"/>
                                                                <span className="input-group-prepend">
                                                            <button className="btn quantity-right-plus"
                                                                    onClick={() => this.incrementQty(item)}
                                                                    data-type="plus"
                                                                // disabled={(item.qty >= item.stock) ? true : false}
                                                            >
                                                            <i className="fa fa-angle-right"/>
                                                            </button>
                                                           </span>
                                                            </div>
                                                        </div>
                                                        {/*{(item.qty >= item.stock) ? 'out of stock' : ''}*/}
                                                    </td>
                                                    <td><h2 style={{
                                                        fontSize: 16,
                                                        color: '#222',
                                                        fontWeight: 700
                                                    }}
                                                            className="td-color">{symbol} {number_format(grandPrice * item.qty, 0)}</h2>
                                                    </td>
                                                    <td className={styles.removeBtn}>
                                                        <a href="#" className="icon"
                                                           onClick={() => this.removeItem(item)}>
                                                            {/*<i className="fa fa-times"/>&nbsp;*/}
                                                            remove
                                                        </a>
                                                    </td>

                                                </tr>
                                                </React.Fragment>
                                            );
                                        })}
                                        </tbody>
                                    </table>

                                    <table className={styles.cartTableMobile}>
                                        <tbody>
                                        {onlineCart.cart_items.map((item, index) => {
                                            const retailPrice = parseInt(item.product_detail.retail_price);
                                            const netPrice = parseInt(item.product_detail.net_price);
                                            // const directDiscPercent = !empty(item.product_detail.direct_discount)?parseFloat(item.product_detail.direct_discount.direct_disc_percent):0;
                                            // const directDiscNominal = !empty(item.product_detail.direct_discount)?parseInt(item.product_detail.direct_discount.direct_disc_nominal):0;
                                            // const grandPrice = (netPrice * ((100-directDiscPercent)/100)).toFixed(0) - directDiscNominal;
                                            const grandPrice = netPrice;

                                            totalMobile += grandPrice * item.qty;

                                            let coverImage = item.product_detail.product_images[0]?.image_url;
                                            item.product_detail.product_images.find(productImage => {
                                                if(productImage.image_type === 'COVER'){
                                                    if(!empty(productImage.image_url)){
                                                        coverImage = productImage.image_url;
                                                    }
                                                }
                                            });

                                            return (
                                                <React.Fragment key={index}>
                                                    <tr className={styles.mobileCartItem}>
                                                        <td colSpan={7}>
                                                            <div className={styles.productImage}>
                                                                <Link
                                                                    to={`${process.env.PUBLIC_URL}/left-sidebar/product/${item.id}`}>
                                                                    <img src={coverImage} alt=""/>
                                                                </Link>
                                                            </div>

                                                            <div className={styles.productRight}>
                                                                <Link
                                                                    className={styles.productName}
                                                                    to={`${process.env.PUBLIC_URL}/left-sidebar/product/${item.id}`}>{item.product_detail.product_name}</Link>
                                                                {/*<div className="mobile-cart-content row">
                                                                <div className="col-xs-3">
                                                                    <div className="qty-box">
                                                                        <div className="input-group">
                                                                            <input type="text" name="quantity"
                                                                                   className="form-control input-number"
                                                                                   defaultValue={item.qty}/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-xs-3">
                                                                    <h2 className="td-color">{symbol} {number_format(grandPrice, 0)}</h2>
                                                                </div>
                                                                <div className="col-xs-3">
                                                                    <h2 className="td-color">
                                                                        <a href="#" className="icon"
                                                                           onClick={() => this.props.removeFromCart(item)}>
                                                                            <i className="icon-close"/>
                                                                        </a>
                                                                    </h2>
                                                                </div>
                                                            </div>*/}

                                                                <h2 className={styles.productSize}
                                                                >Size: {item.size_name}</h2>

                                                                <h2 className={styles.productPrice}
                                                                >
                                                                    {retailPrice !== grandPrice && (
                                                                        <div style={{marginBottom: 5}}>
                                                                            <del><span className="money">{symbol} {number_format(retailPrice, 0)}</span></del>
                                                                        </div>
                                                                    )}
                                                                    {symbol} {number_format(grandPrice, 0)}
                                                                </h2>
                                                            </div>

                                                            <div className={styles.productOptions}>
                                                                <div className={`qty-box ${styles.productQty}`}>
                                                                    <div className="input-group">
                                                                        <span className="input-group-prepend">
                                                                            <button type="button"
                                                                                    className="btn quantity-left-minus"
                                                                                    onClick={() => this.decrementQty(item)}
                                                                                    data-type="minus" data-field="">
                                                                             <i className="fa fa-angle-left"/>
                                                                            </button>
                                                                        </span>
                                                                        <input type="text" name="quantity" value={item.qty}
                                                                               readOnly={true}
                                                                               className="form-control input-number"/>
                                                                        <span className="input-group-prepend">
                                                                            <button className="btn quantity-right-plus"
                                                                                    onClick={() => this.incrementQty(item)}
                                                                                    data-type="plus"
                                                                                // disabled={(item.qty >= item.stock) ? true : false}
                                                                            >
                                                                                <i className="fa fa-angle-right"/>
                                                                            </button>
                                                                        </span>
                                                                    </div>
                                                                </div>

                                                                <h2 className={styles.productGrandPrice}>{symbol} {number_format(grandPrice * item.qty, 0)}
                                                                </h2>

                                                                <div className={styles.removeBtn}>
                                                                    <a href="#" className="icon"
                                                                       onClick={() => this.removeItem(item)}>
                                                                        {/*<i className="fa fa-times"/>&nbsp;*/}
                                                                        remove
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </React.Fragment>
                                            );
                                        })}
                                        </tbody>
                                    </table>

                                    <table className={`table cart-table table-responsive-md ${styles.footerTable}`}>
                                        <tfoot>
                                        <tr>
                                            <td style={{width: 'auto'}}>total price</td>
                                            <td style={{width: 300}}><h2>{symbol} {number_format(totalMobile, 0)} </h2>
                                            </td>
                                        </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                            <div className={`row cart-buttons ${styles.cartButtons}`}>
                                <div className="col-6">
                                    <Link to={`${process.env.PUBLIC_URL}/products`}
                                          className="btn btn-solid">continue shopping</Link>
                                </div>
                                <div className="col-6">
                                    {!this.props.authenticated && (
                                        <>
                                            <Link style={{margin: 10}} to={`${process.env.PUBLIC_URL}/sign-in`} className="btn btn-solid">Sign In To Purchase</Link>
                                            {/*<Link style={{margin: 10}} to={`${process.env.PUBLIC_URL}/checkout`} className="btn btn-solid">Checkout Without Sign In</Link>*/}
                                        </>
                                    )}

                                    {this.props.authenticated && (
                                        <Link style={{margin: 10}} to={`${process.env.PUBLIC_URL}/checkout`} className="btn btn-solid">Checkout</Link>
                                    )}
                                </div>
                            </div>

                            <div className={`row cart-buttons ${styles.mobileCartButtons}`}>
                                {!this.props.authenticated && (
                                    <>
                                        <Link to={`${process.env.PUBLIC_URL}/sign-in`} className="btn btn-solid">Sign In</Link>
                                        {/*<Link to={`${process.env.PUBLIC_URL}/checkout`} className="btn btn-solid">Checkout Without Sign In</Link>*/}
                                    </>
                                )}

                                {this.props.authenticated && (
                                    <Link to={`${process.env.PUBLIC_URL}/checkout`} className="btn btn-solid">Checkout</Link>
                                )}

                                <Link to={`${process.env.PUBLIC_URL}/products`}
                                      className={`btn btn-solid ${styles.continueShoppingBtn}`}>continue shopping</Link>
                            </div>
                        </div>
                    </section>
                )}

                {/*<div>
                    <form method="post" action="https://dev.faspay.co.id/xpress/payment">
                        <input type="hidden" name="merchant_id" value="33125" />
                        <input type="hidden" name="merchant_name" value="Hammer" />
                        <input type="hidden" name="order_id" value="a3" />
                        <input type="hidden" name="order_reff" value="a3" />
                        <input type="hidden" name="bill_date" value="2020-06-12 08:01:00" />
                        <input type="hidden" name="bill_expired" value="2020-06-15 08:01:00" />
                        <input type="hidden" name="bill_desc" value="Hammer E-Commerce Online Sales" />
                        <input type="hidden" name="bill_total" value="159000" />
                        <input type="hidden" name="custNo" value="c1" />
                        <input type="hidden" name="return_url" value="https://ecom.warna-mardhika.com" />
                        <input type="hidden" name="display_cust" value="true" />
                        <input type="hidden" name="custName" value="Rolando" />
                        <input type="hidden" name="custPhone" value="081284435553" />
                        <input type="hidden" name="custEmail" value="rolando_kai@yahoo.com" />
                        <input type="hidden" name="products" value="1x Coconut Island Kaos Basic Wanita Tes" />
                        <input type="hidden" name="signature" value={SHA1(MD5('bot33125'+'p@ssw0rd'+'a3'+'159000').toString())} />
                        <input type="hidden" name="term_condition" value="1" />
                        <button type="submit">Pay</button>
                    </form>
                </div>*/}

                {(empty(onlineCart) || empty(onlineCart.cart_items)) && (
                    <section className="cart-section section-b-space">
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div>
                                        <div className="col-sm-12 empty-cart-cls text-center">
                                            <img src={`${process.env.PUBLIC_URL}/assets/images/icon-empty-cart.png`}
                                                 className="img-fluid mb-4" alt=""/>
                                            <h3>
                                                <strong>Your Cart is Empty</strong>
                                            </h3>
                                            <h4>Explore more of our collections</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                )}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    onlineCart: state.cartList.onlineCart,
    cartItems: state.cartList.cart,
    symbol: state.data.symbol,
    total: getCartTotal(state.cartList.cart),
    authenticated: state.auth.authenticated
});

export default connect(
    mapStateToProps,
    {removeFromCart, incrementQty, decrementQty, retrieveCart, openCart, closeCart}
)(cartComponent)