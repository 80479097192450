import React, {Component} from 'react';
import {Helmet} from "react-helmet";
import styles from './CategoryLanding.module.scss';
import FooterOne from "./common/footers/footer-one";
import {svgFreeShipping, svgoffer, svgservice} from "../services/script";
import {Link} from "react-router-dom";

class CategoryLanding extends Component {
    constructor(props) {
        super(props);

        this.state = {
            category: '',
            banner: '',
            bannerMobile: '',
            image1: '',
            image2: '',
            image3: '',
            mobileMenuOpened: false,
            title11: '',
            title12: '',
            title21: '',
            title22: '',
            title31: '',
            title32: '',
            subtitle1: '',
            subtitle2: '',
            subtitle3: ''
        };
    }

    componentDidMount() {
        setTimeout(function () {
            document.querySelector(".loader-wrapper").style = "display: none";
        }, 2000);

        if (this.props.match.params.category === 'men') {
            this.setState({
                category: 'men',
                banner: `${process.env.PUBLIC_URL}/assets/images/wm/ci/men-banner.jpg`,
                bannerMobile: `${process.env.PUBLIC_URL}/assets/images/wm/ci/men-banner-mobile.jpg`,
                image1: `${process.env.PUBLIC_URL}/assets/images/wm/ci/men-1.jpeg`,
                image2: `${process.env.PUBLIC_URL}/assets/images/wm/ci/men-2.jpeg`,
                image3: `${process.env.PUBLIC_URL}/assets/images/wm/ci/men-3.jpeg`,
                title11: 'SHOP',
                title12: 'SWEATSHIRTS',
                title21: 'SHOP',
                title22: 'TROPICAL SHIRTS',
                title31: 'SHOP',
                title32: 'GRAPHIC TEES',
                subtitle1: 'Hit the street with style',
                subtitle2: 'Relax and still in style throughout the day',
                subtitle3: 'Embrace your quirks. Speak with your outfit'
            });

        } else {
            this.setState({
                category: 'women',
                banner: `${process.env.PUBLIC_URL}/assets/images/wm/ci/women-banner.jpg`,
                bannerMobile: `${process.env.PUBLIC_URL}/assets/images/wm/ci/women-banner-mobile.jpg`,
                image1: `${process.env.PUBLIC_URL}/assets/images/wm/ci/women-1.jpeg`,
                image2: `${process.env.PUBLIC_URL}/assets/images/wm/ci/women-2.jpeg`,
                image3: `${process.env.PUBLIC_URL}/assets/images/wm/ci/women-3.jpeg`,
                title11: 'SHOP',
                title12: 'STATEMENT TEES',
                title21: 'SHOP',
                title22: 'GRAPHIC TEES',
                title31: 'SHOP',
                title32: 'SWEATSHIRTS',
                subtitle1: 'Say what you need to say',
                subtitle2: 'Express yourself through your t-shirt',
                subtitle3: 'Get you confident look. Stay cozy at its best'
            });
        }
    }

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>Coconut Island | PT Warna Mardhika</title>
                    <meta name="description"
                          content="Inspired by an Island in Rio de Janeiro, Brazil. COCONUT ISLAND rapidly developing into a premium brand t-shirt in Indonesia."/>
                </Helmet>

                <div className={styles.mobileMenu} style={{display: this.state.mobileMenuOpened ? 'block' : 'none'}}>
                    <div className={styles.closeBtn}>
                        <i className="fa fa-remove"
                           onClick={() => {
                               this.setState({
                                   mobileMenuOpened: false
                               });
                           }}
                        />
                    </div>
                    <div className={styles.headerLogo}>
                        <Link to="/">
                            <img src={`${process.env.PUBLIC_URL}/assets/images/wm/logo-coconut.png`}
                                 className="img-fluid" alt="Coconut Island"/>
                        </Link>
                    </div>

                    <div className={styles.headerMenu}>
                        <ul>
                            <li><a href={`/shop/men`}>Men</a></li>
                            <li><a href={`/shop/women`}>Women</a></li>
                            <li><a href={`/products?group_id=3`}>Kids</a></li>
                            <li><Link to={`/products`}>Shop All</Link></li>
                            <li><Link to={`/hashtag`}>#iamCOCONUTs</Link></li>
                        </ul>
                    </div>
                </div>

                <section style={{paddingTop: 0}} className={styles.hero}>
                    <div className={styles.fsHeroItem}>
                        <div className={styles.fsHeroVideoWrapper}>
                            {(window.innerWidth > 991) ? (
                                <img src={this.state.banner} alt="Coconut Island"/>
                            ) : (
                                <img src={this.state.bannerMobile} alt="Coconut Island"/>
                            )}
                        </div>

                        <div className={`${styles.fsHeroContentWrapper}`}>
                            <header>
                                <div className={styles.headerMenu}>
                                    <ul>
                                        <li><Link to={`${process.env.PUBLIC_URL}/products`}>T-Shirts</Link></li>
                                        <li><Link to={`${process.env.PUBLIC_URL}/products`}>Pants</Link></li>
                                        <li><Link to={`${process.env.PUBLIC_URL}/products`}>Jackets</Link></li>
                                        <li><Link to={`${process.env.PUBLIC_URL}/products`}>Accessories</Link></li>
                                        <li><Link to={`${process.env.PUBLIC_URL}/hashtag`}>#iamCOCONUTs</Link></li>
                                    </ul>
                                </div>

                                <div className={styles.headerMenuToggle}>
                                    <i className="fa fa-bars" onClick={() => {
                                        this.setState({
                                            mobileMenuOpened: true
                                        });
                                    }}/>
                                </div>

                                <div className={styles.headerSearch}>
                                    <form action="/products" method="get">
                                        <i className="fa fa-search"/>
                                        <input type="text" name="search" placeholder="Search"/>
                                    </form>
                                </div>

                                <div className={styles.headerLogo}>
                                    <Link to={`${process.env.PUBLIC_URL}`}>
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/wm/logo-coconut.png`}
                                             className="img-fluid" alt="Coconut Island"/>
                                    </Link>
                                </div>
                            </header>

                            <div className={styles.fsHeroContent}>
                                <h1 className={styles.fsHeroContentTitle}>New In<br/>{this.state.category}</h1>
                                <Link className={styles.fsHeroContentActionBtn} to="/products">SHOP NOW</Link>
                            </div>
                        </div>
                    </div>
                </section>

                <section className={styles.row1}>
                    <div className={styles.col1}>
                        <img src={this.state.image1} alt="ROW1"/>
                    </div>
                    <div className={styles.col2}>
                        <div className={styles.thinTitle}>
                            {this.state.title11}
                        </div>
                        <div className={styles.thickTitle}>
                            {this.state.title12}
                        </div>
                        <div className={styles.subtitle}>
                            {this.state.subtitle1}
                        </div>

                        <div>
                            <Link className={styles.actionBtn} to="/products">SHOP</Link>
                        </div>
                    </div>
                </section>

                <section className={styles.row2}>
                    <div className={styles.col1}>
                        <div className={styles.thinTitle}>
                            {this.state.title21}
                        </div>
                        <div className={styles.thickTitle}>
                            {this.state.title22}
                        </div>
                        <div className={styles.subtitle}>
                            {this.state.subtitle2}
                        </div>

                        <div>
                            <Link className={styles.actionBtn} to="/products">SHOP</Link>
                        </div>
                    </div>
                    <div className={styles.col2}>
                        <img src={this.state.image2} alt="ROW2"/>
                    </div>
                </section>

                <section className={styles.row1}>
                    <div className={styles.col1}>
                        <img src={this.state.image3} alt="ROW3"/>
                    </div>
                    <div className={styles.col2} style={{textAlign: 'left'}}>
                        <div className={styles.thinTitle}>
                            {this.state.title31}
                        </div>
                        <div className={styles.thickTitle}>
                            {this.state.title32}
                        </div>
                        <div className={styles.subtitle}>
                            {this.state.subtitle3}
                        </div>

                        <div>
                            <Link className={styles.actionBtn} to="/products">SHOP</Link>
                        </div>
                    </div>
                </section>

                <section className={styles.hashtagSection}>
                    <h2><Link style={{color: 'inherit'}} to='/hashtag'>#iamCOCONUTs</Link></h2>
                    <p>
                        Share your personal style and see how others are styling their favourites from Coconut Island.<br/>Tag
                        your picture with @coconutislandclothing.id and #iamCOCONUTs for a chance to be featured on coconutisland75.com
                        and in our stores.
                    </p>
                    <div className={styles.hashtagPhotos}>
                        <div className={styles.hashtagPhoto}>
                            <a href="https://www.instagram.com/coconutislandclothing.id" target="_blank" rel="noopener noreferrer">
                                <img src={`${process.env.PUBLIC_URL}/assets/images/wm/ci/hashtag-1.jpeg`}
                                     alt="HashTag Photo"/>
                                <div className={styles.hashtagPhotoOverlay}>
                                    <div className={styles.hashtagPhotoText}>
                                        @instagram_account
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className={styles.hashtagPhoto}>
                            <a href="https://www.instagram.com/coconutislandclothing.id" target="_blank" rel="noopener noreferrer">
                                <img src={`${process.env.PUBLIC_URL}/assets/images/wm/ci/hashtag-2.jpg`}
                                     alt="HashTag Photo"/>
                                <div className={styles.hashtagPhotoOverlay}>
                                    <div className={styles.hashtagPhotoText}>
                                        @instagram_account
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className={styles.hashtagPhoto}>
                            <a href="https://www.instagram.com/coconutislandclothing.id" target="_blank" rel="noopener noreferrer">
                                <img src={`${process.env.PUBLIC_URL}/assets/images/wm/ci/hashtag-3.jpg`}
                                     alt="HashTag Photo"/>
                                <div className={styles.hashtagPhotoOverlay}>
                                    <div className={styles.hashtagPhotoText}>
                                        @instagram_account
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </section>

                <div className="container-fluid" style={{background: 'white'}}>
                    <section className={`service border-section small-section ${styles.serviceSection}`}>
                        <div className="row">
                            <div className="col-md-4 service-block">
                                <div className="media">
                                    <div dangerouslySetInnerHTML={{__html: svgFreeShipping}}/>
                                    <div className="media-body">
                                        <h4>store pickup</h4>
                                        <p>choose our nearby outlets</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 service-block">
                                <div className="media">
                                    <div dangerouslySetInnerHTML={{__html: svgservice}}/>
                                    <div className="media-body">
                                        <h4>fast process</h4>
                                        <p>within 1x24 hour guarantee</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 service-block">
                                <div className="media">
                                    <div dangerouslySetInnerHTML={{__html: svgoffer}}/>
                                    <div className="media-body">
                                        <h4>generous offers</h4>
                                        <p>enjoy our seasonal promo</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                <FooterOne logoName={'logo.png'}/>
            </React.Fragment>
        );
    }
}

export default CategoryLanding;