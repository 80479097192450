import React, {Component} from 'react';
import {Helmet} from 'react-helmet'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import {getCartTotal} from "../services";
import {decrementQty, incrementQty, removeFromCart, retrieveCart} from '../actions'
import {number_format} from "../helpers/stringHelper";
import {empty} from "../helpers/generalHelper";
import styles from './PaymentResponse.module.scss';
import {SHIPPING_TYPE_ONLINE} from "../utils/constants";
import {apiCall} from "../helpers/networkHelper";
import {CHECKOUT, CHECKOUT_PAYMENT, CHECKOUT_TRX} from "../utils/endpoints";
import ErrorHandler from "../classes/ErrorHandler";
import {Loader} from "react-overlay-loader";

class PaymentResponse extends Component {

    constructor(props) {
        super(props);

        this.state = {
            page: 'choose-shipping-type',
            pageLoading: false,
            loadingText: "",
            availabilityCheckFailed: false,
            availableOnline: null,
            availableOnStore: null,
            availableStores: null,
            availableStoresByRegion: null,
            shippingType: '',

            user_addresses: [],
            selectedUserAddressId: null,
            provinces: [],
            cities: [],
            subdistricts: [],

            showAddressForm: false,
            address_label: '',
            recipient_name: '',
            recipient_phone: '',
            address_street: '',
            province_id: null,
            city_id: null,
            subdistrict_id: null,
            address_notes: '',

            shipping_meta: null,
            shipping_methods: [],
            selected_shipping_method: null,

            showrooms: [],
            selectedShowroomId: null,
            showroomFilter: '',

            paymentForm: null
        };

        this.emptyAddressForm = {
            address_label: '',
            recipient_name: '',
            recipient_phone: '',
            address_street: '',
            province_id: null,
            city_id: null,
            subdistrict_id: null,
            address_notes: '',
        };

        this.emptyShippingMethod = {
            shipping_meta: null,
            shipping_methods: [],
            selected_shipping_method: null,
        };

        this.paymentForm = React.createRef();
    }

    componentDidMount() {
        this.confirmPayment();
    }

    showLoader = (loadingText = 'Loading..') => {
        this.setState({
            pageLoading: true,
            loadingText
        });
    };

    hideLoader = () => {
        this.setState({
            pageLoading: false,
            loadingText: ''
        });
    };

    confirmPayment = () => {
        const urlParams = new URLSearchParams(window.location.search);

        this.showLoader();
        apiCall(
            CHECKOUT_PAYMENT,
            'post',
            {
                trx_id: urlParams.get('trx_id'),
                merchant_id: urlParams.get('merchant_id'),
                merchant: urlParams.get('merchant'),
                bill_no: urlParams.get('bill_no'),
                bill_ref: urlParams.get('bill_ref'),
                bill_total: urlParams.get('bill_total'),
                payment_reff: urlParams.get('payment_reff'),
                payment_date: urlParams.get('payment_date'),
                bank_user_name: urlParams.get('bank_user_name'),
                status: urlParams.get('status'),
                signature: urlParams.get('signature')
            },
            res => {
                console.log(res.data);
            },
            err => {
                this.setState({availabilityCheckFailed: true});
                ErrorHandler.handleGeneralError(err);
            },
            res => {
                this.hideLoader();
            }
        )
    };

    goToPayment = () => {
        const {
            shippingType,
            selectedUserAddressId,
            shipping_meta,
            shipping_methods,
            selected_shipping_method,
            selectedShowroomId,
            recipient_name,
            recipient_phone,
            address_street,
            province_id,
            city_id,
            subdistrict_id,
            address_notes
        } = this.state;

        this.showLoader();
        apiCall(
            CHECKOUT_TRX,
            'post',
            {
                local_device_id: localStorage.getItem('local_device_id'),
                shipping_type: shippingType,
                showroom_id: selectedShowroomId,

                user_address_id: selectedUserAddressId,
                address_recipient_name: recipient_name,
                address_recipient_phone: recipient_phone,
                address_street,
                province_id,
                city_id,
                subdistrict_id,
                address_notes,

                shipping_courier: selected_shipping_method && selected_shipping_method.name,
                shipping_service_name: selected_shipping_method && selected_shipping_method.service,
                shipping_service_desc: selected_shipping_method && selected_shipping_method.description,
                shipping_etd: selected_shipping_method && selected_shipping_method.etd,
                shipping_cost: selected_shipping_method && selected_shipping_method.cost,
                shipping_notes: selected_shipping_method && selected_shipping_method.notes
            },
            res => {
                console.log(res.data);
                this.setState({
                    paymentForm: res.data.payment_data
                }, () => {
                    // console.log(this.paymentForm)
                    this.paymentForm.current.submit();
                });
            },
            err => {
                ErrorHandler.handleGeneralError(err);
            },
            res => {
                this.hideLoader();
            }
        )
    };

    render() {
        const {onlineCart, symbol, authenticated} = this.props;
        const {
            page,
            pageLoading,
            loadingText,
            availabilityCheckFailed,
            availableOnline,
            availableOnStore,
            availableStoresByRegion,

            shippingType,

            user_addresses,
            provinces,
            cities,
            subdistricts,

            showAddressForm,
            address_label,
            recipient_name,
            recipient_phone,
            address_street,
            province_id,
            city_id,
            subdistrict_id,
            address_notes,

            shipping_methods,
            selected_shipping_method,

            paymentForm
        } = this.state;

        let total = 0;
        let shippingCost = 0;
        let shippingString = '';

        if(shippingType === SHIPPING_TYPE_ONLINE){
            if(!empty(selected_shipping_method)){
                shippingCost = parseInt(selected_shipping_method.cost);
                shippingString = selected_shipping_method.code.toUpperCase() + ' - ' + selected_shipping_method.service;
            }
        }else{
            shippingString = 'Store Pickup'
        }

        return (
            <div>
                {/*SEO Support*/}
                <Helmet>
                    <title>Coconut Island | PT Warna Mardhika</title>
                    <meta name="description"
                          content="Multikart – Multipurpose eCommerce React Template is a multi-use React template. It is designed to go well with multi-purpose websites. Multikart Bootstrap 4 Template will help you run multiple businesses."/>
                </Helmet>
                {/*SEO Support End */}

                <Loader fullPage loading={pageLoading} text={loadingText}/>

                <h1 style={{
                    textAlign: 'center',
                    fontFamily: "'DM Sans', sans-serif",
                    marginTop: 50,
                    marginBottom: 50,
                    fontSize: '40px',
                    fontWeight: 700
                }}
                >Order Success</h1>

                <div className="container" style={{marginBottom: 50}}>
                    <div className="row">
                        <div className="col-md-12">
                            {this.props.authenticated && (
                                <>
                                    <h3 style={{textAlign: 'center'}}>Thank you for shopping!</h3>

                                    <div style={{textAlign: 'center', marginTop: 30}}>
                                        <Link to="/account/orders">
                                            <button className="btn btn-solid">Check My Order Status</button>
                                        </Link>
                                    </div>
                                </>
                            )}

                            {!this.props.authenticated && (
                                <>
                                    <h3 style={{textAlign: 'center'}}>Thank you for shopping, please check your email for further information.</h3>

                                    <div style={{textAlign: 'center', marginTop: 30}}>
                                        <Link to="/products">
                                            <button className="btn btn-solid">Continue Shopping</button>
                                        </Link>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    onlineCart: state.cartList.onlineCart,
    cartItems: state.cartList.cart,
    symbol: state.data.symbol,
    total: getCartTotal(state.cartList.cart),
    authenticated: state.auth.authenticated
});

export default connect(
    mapStateToProps,
    {removeFromCart, incrementQty, decrementQty, retrieveCart}
)(PaymentResponse)