import React, {Component} from 'react';
import {Helmet} from 'react-helmet'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import {getCartTotal} from "../services";
import {decrementQty, incrementQty, removeFromCart, retrieveCart} from '../actions'
import {number_format} from "../helpers/stringHelper";
import {empty} from "../helpers/generalHelper";
import styles from './Checkout.module.scss';
import {Input, message, Select} from "antd";
import {FASPAY_XPRESS_URL, SHIPPING_TYPE_ONLINE, SHIPPING_TYPE_PICKUP} from "../utils/constants";
import {apiCall} from "../helpers/networkHelper";
import {
    CHECK_SHOWROOM_STOCK_AVAILABILITY,
    CHECKOUT,
    CHECKOUT_PROMO_CODE,
    CHECKOUT_SHIPPING,
    CHECKOUT_TRX,
    MASTER_CITY,
    MASTER_PROVINCE,
    MASTER_SHOWROOM,
    MASTER_SUBDISTRICT,
    USER_ADDRESS
} from "../utils/endpoints";
import ErrorHandler from "../classes/ErrorHandler";
import {Loader} from "react-overlay-loader";
import {RED} from "../utils/colors";

class Checkout extends Component {

    constructor(props) {
        super(props);

        this.state = {
            page: 'choose-shipping-type',
            pageLoading: false,
            loadingText: "",
            availabilityCheckFailed: false,
            availableOnStore: null,
            availableStores: null,
            availableStoresByRegion: [],

            availableOnline: null,
            availableStoresOnline: null,
            availableStoresByRegionOnline: [],

            shippingType: '',

            guest_email: '',

            user_addresses: [],
            selectedUserAddressId: null,
            provinces: [],
            cities: [],
            subdistricts: [],

            showAddressForm: false,
            address_label: '',
            recipient_name: '',
            recipient_phone: '',
            recipient_zip: '',
            address_street: '',
            province_id: null,
            city_id: null,
            subdistrict_id: null,
            address_notes: '',

            shipping_meta: null,
            shipping_methods: [],
            selected_shipping_method: null,

            showrooms: [],
            selectedShowroomId: null,
            showroomFilter: '',

            paymentForm: null,

            promoCode: '',
            promo: null,
            total_purchase: 0,
            cartItemsAvailability: []
        };

        this.emptyAddressForm = {
            address_label: '',
            recipient_name: '',
            recipient_phone: '',
            recipient_zip: '',
            address_street: '',
            province_id: null,
            city_id: null,
            subdistrict_id: null,
            address_notes: '',
        };

        this.emptyShippingMethod = {
            shipping_meta: null,
            shipping_methods: [],
            selected_shipping_method: null,
        };

        this.paymentForm = React.createRef();
    }

    componentDidMount() {
        this.checkAvailability();
        this.loadProvinces();
    }

    showLoader = (loadingText = 'Loading..') => {
        this.setState({
            pageLoading: true,
            loadingText
        });
    };

    hideLoader = () => {
        this.setState({
            pageLoading: false,
            loadingText: ''
        });
    };

    checkAvailability = () => {
        this.showLoader();
        apiCall(
            CHECKOUT,
            'post',
            {
                local_device_id: localStorage.getItem('local_device_id')
            },
            res => {
                this.setState({
                    availableOnStore: res.data.available_on_store,
                    availableStores: res.data.available_stores,
                    availableStoresByRegion: res.data.available_stores_by_region,
                    availableOnline: res.data.available_online,
                    availableStoresOnline: res.data.available_stores_online,
                    availableStoresByRegionOnline: res.data.available_stores_by_region_online,
                });
            },
            err => {
                this.setState({availabilityCheckFailed: true});
                ErrorHandler.handleGeneralError(err);
            },
            res => {
                this.hideLoader();
            }
        )
    };

    loadShowrooms = () => {
        apiCall(
            MASTER_SHOWROOM,
            'get',
            null,
            res => {
                this.setState({
                    showrooms: res.data.showroom_list
                })
            },
            err => {
                ErrorHandler.handleGeneralError(err);
            }
        )
    };

    loadShowroomRegions = () => {
        apiCall(
            MASTER_SHOWROOM,
            'get',
            null,
            res => {
                this.setState({
                    showrooms: res.data.showroom_list
                })
            },
            err => {
                ErrorHandler.handleGeneralError(err);
            }
        )
    };

    loadProvinces = () => {
        apiCall(
            MASTER_PROVINCE,
            'get',
            null,
            res => {
                this.setState({
                    provinces: res.data.province_list
                })
            },
            err => {
                ErrorHandler.handleGeneralError(err);
            }
        )
    };

    loadCities = () => {
        this.showLoader('Loading cities..');
        apiCall(
            MASTER_CITY + `?province_id=${this.state.province_id}`,
            'get',
            null,
            res => {
                this.setState({
                    cities: res.data.city_list
                })
            },
            err => {
                ErrorHandler.handleGeneralError(err);
            },
            res => {
                this.hideLoader();
            }
        )
    };

    loadSubdistrict = () => {
        this.showLoader('Loading subdistricts..');
        apiCall(
            MASTER_SUBDISTRICT + `?city_id=${this.state.city_id}`,
            'get',
            null,
            res => {
                this.setState({
                    subdistricts: res.data.subdistrict_list
                })
            },
            err => {
                ErrorHandler.handleGeneralError(err);
            },
            res => {
                this.hideLoader();
            }
        )
    };

    loadUserAddresses = () => {
        this.showLoader('Loading your addresses..');
        apiCall(
            USER_ADDRESS,
            'get',
            null,
            res => {
                this.setState({
                    user_addresses: res.data.user_addresses
                })
            },
            err => {
                ErrorHandler.handleGeneralError(err);
            },
            res => {
                this.hideLoader();
            }
        )
    };

    loadShippingMethods = (isHub=false) => {
        let subdistrict_id = 0;
        if(this.props.authenticated){
            const selectedAddress = this.getUserAddressById(this.state.selectedUserAddressId);
            subdistrict_id = selectedAddress.subdistrict_id;
        }else{
            subdistrict_id = this.state.subdistrict_id
        }

        let selectedShowroomId = null;
        if(isHub) selectedShowroomId = this.state.selectedShowroomId;

        this.showLoader('Loading your addresses..');
        apiCall(
            CHECKOUT_SHIPPING,
            'post',
            {
                local_device_id: localStorage.getItem('local_device_id'),
                subdistrict_id,
                showroom_id: selectedShowroomId
            },
            res => {
                let mapped_shipping_methods = [];

                res.data.sicepat.map(shipping_method => {
                    // let price = shipping_method.unitPrice * parseFloat(shipping_method.weight);
                    // if(price < shipping_method.minPrice){
                    //     price = shipping_method.minPrice;
                    // }

                    mapped_shipping_methods = mapped_shipping_methods.concat({
                        code: shipping_method.service,
                        name: 'SICEPAT',
                        service: shipping_method.description,
                        description: shipping_method.description,
                        cost: shipping_method.tariff,
                        etd: shipping_method.etd,
                        notes: ''
                    });
                    return shipping_method;
                });

                this.setState({
                    shipping_meta: res.data.sicepat,
                    shipping_methods: mapped_shipping_methods
                });
            },
            err => {
                ErrorHandler.handleGeneralError(err);
            },
            res => {
                this.hideLoader();
            }
        )
    };

    getShowroomById = id => {
        const {showrooms} = this.state;
        return showrooms.find(showroom => showroom.id === id);
    };

    getProvinceById = id => {
        const {provinces} = this.state;
        return provinces.find(province => province.id === id);
    };

    getCityById = id => {
        const {cities} = this.state;
        return cities.find(city => city.id === id);
    };

    getSubdistrictById = id => {
        const {subdistricts} = this.state;
        return subdistricts.find(subdistrict => subdistrict.id === id);
    };

    getUserAddressById = id => {
        const {user_addresses} = this.state;
        return user_addresses.find(user_address => user_address.id === id);
    };

    onChangeAddressLabel = e => {
        this.setState({address_label: e.target.value});
    };

    onChangeRecipientName = e => {
        this.setState({recipient_name: e.target.value});
    };

    onChangeRecipientPhone = e => {
        this.setState({recipient_phone: e.target.value});
    };

    onChangeRecipientZip = e => {
        this.setState({recipient_zip: e.target.value});
    };

    onChangeAddressStreet = e => {
        this.setState({address_street: e.target.value});
    };

    onChangeAddressNotes = e => {
        this.setState({address_notes: e.target.value});
    };

    onChangeProvince = (value, option) => {
        this.setState({
            province_id: value
        }, () => {
            this.loadCities();
        });
    };

    onChangeCity = (value, option) => {
        this.setState({
            city_id: value
        }, () => {
            this.loadSubdistrict();
        });
    };

    onChangeSubdistrict = (value, option) => {
        this.setState({
            subdistrict_id: value
        });
    };

    onSelectShowroom = showroomId => {
        this.showLoader('Checking your cart items availability..');
        const form_data = {
            local_device_id: localStorage.getItem('local_device_id'),
            showroom_id: showroomId
        };

        apiCall(
            CHECK_SHOWROOM_STOCK_AVAILABILITY,
            'post',
            form_data,
            (res) => {
                this.setState({
                    page: 'order-summary',
                    cartItemsAvailability: res.data.cart_items_availability,
                    selectedShowroomId: showroomId
                });
            },
            (err) => {
                ErrorHandler.handleGeneralError(err);
            },
            (res) => {
                this.hideLoader();
            },
        );
    };

    onSelectHub = showroomId => {
        this.showLoader('Checking your cart items availability..');
        const form_data = {
            local_device_id: localStorage.getItem('local_device_id'),
            showroom_id: showroomId
        };

        apiCall(
            CHECK_SHOWROOM_STOCK_AVAILABILITY,
            'post',
            form_data,
            (res) => {
                this.setState({
                    page: 'choose-shipping-method',
                    cartItemsAvailability: res.data.cart_items_availability,
                    selectedShowroomId: showroomId
                }, () => {
                    this.loadShippingMethods(true);
                });
            },
            (err) => {
                ErrorHandler.handleGeneralError(err);
            },
            (res) => {
                this.hideLoader();
            },
        );
    };

    onAddNewAddressBtnClicked = () => {
        this.setState({
            showAddressForm: true,
            ...this.emptyAddressForm
        });
    };

    onSubmitNewAddress = () => {
        const {
            address_label,
            recipient_name,
            recipient_phone,
            recipient_zip,
            address_street,
            province_id,
            city_id,
            subdistrict_id,
            address_notes
        } = this.state;
        let valid_form = true;

        if(empty(address_label)){
            message.error('Address label is required.');
            valid_form = false;
        }
        if(empty(recipient_name)){
            message.error('Recipient name is required.');
            valid_form = false;
        }
        if(empty(recipient_phone)){
            message.error('Recipient phone is required.');
            valid_form = false;
        }
        if(empty(recipient_zip)){
            message.error('Recipient Zipcode is required.');
            valid_form = false;
        }
        if(empty(address_street)){
            message.error('Address street is required.');
            valid_form = false;
        }
        if(empty(province_id)){
            message.error('Province must be chosen.');
            valid_form = false;
        }
        if(empty(city_id)){
            message.error('City must be chosen');
            valid_form = false;
        }
        if(empty(subdistrict_id)){
            message.error('Subdistrict must be chosen.');
            valid_form = false;
        }

        if(valid_form){
            this.showLoader('Saving your new address..');

            apiCall(
                USER_ADDRESS,
                'post',
                {
                    address_label,
                    recipient_name,
                    recipient_phone,
                    recipient_zip,
                    address_street,
                    province_id,
                    city_id,
                    subdistrict_id,
                    address_notes
                },
                res => {
                    message.success(res.data.message);
                    this.loadUserAddresses();
                    this.setState({
                        showAddressForm: false,
                        ...this.emptyAddressForm
                    });
                },
                err => {
                    ErrorHandler.handleGeneralError(err);
                },
                res => {
                    this.hideLoader();
                }
            );
        }
    };

    onCancelNewAddressForm = () => {
        this.setState({
            showAddressForm: false,
            ...this.emptyAddressForm
        });
    };

    onContinueFromChooseAddress = () => {
        if(this.props.authenticated){
            if(empty(this.state.selectedUserAddressId)){
                message.error('Please choose an address first.');
            }else{
                this.setState({
                    page: 'choose-hub'
                });
            }
        }else{
            const {
                recipient_name,
                recipient_phone,
                address_street,
                province_id,
                city_id,
                subdistrict_id,
            } = this.state;
            let valid_form = true;

            if(empty(recipient_name)){
                message.error('Recipient name is required.');
                valid_form = false;
            }
            if(empty(recipient_phone)){
                message.error('Recipient phone is required.');
                valid_form = false;
            }
            if(empty(address_street)){
                message.error('Address street is required.');
                valid_form = false;
            }
            if(empty(province_id)){
                message.error('Province must be chosen.');
                valid_form = false;
            }
            if(empty(city_id)){
                message.error('City must be chosen');
                valid_form = false;
            }
            if(empty(subdistrict_id)){
                message.error('Subdistrict must be chosen.');
                valid_form = false;
            }

            if(valid_form){
                this.setState({
                    page: 'choose-hub'
                });
            }
        }
    }

    onContinueFromChooseShippingMethod = () => {
        if(empty(this.state.selected_shipping_method)){
            message.error('Please choose a shipping method.');
        }else{
            this.setState({
                page: 'order-summary'
            });
        }
    };

    renderUserAddresses = addresses => {
        return addresses.map(address => {
            return (
                <div
                    className={`${styles.userAddressItem} ${this.state.selectedUserAddressId === address.id ? styles.active : ''}`}
                    key={`UserAddress${address.id}`}
                    onClick={() => {
                        this.setState({
                            selectedUserAddressId: address.id
                        });
                    }}
                >
                    {this.state.selectedUserAddressId === address.id && (
                        <i className="fa fa-check-circle-o" />
                    )}
                    <div className={styles.userAddressLabel}>{address.label} {address.is_primary === 1 ? '(Primary)' : ''}</div>
                    <div className={styles.userAddressName}>{address.recipient_name} ({address.recipient_phone})</div>
                    <div className={styles.userAddressStreet}>
                        {address.address_street}<br/>
                        {address.subdistrict.subdistrict_name}, {address.city.type} {address.city.city_name},<br/>
                        {address.province.province_name}, Indonesia
                    </div>

                    {!empty(address.notes) && (
                        <div className={styles.userAddressNotes}>
                            Notes: {address.notes}
                        </div>
                    )}
                </div>
            );
        });
    };

    renderShowrooms = (showrooms, isHub=false) => {
        let filteredShowrooms = showrooms;
        if(isHub){
            filteredShowrooms = showrooms.filter(showroom => showroom.is_hub === 1);
        }
        return filteredShowrooms.map(showroom => {
            return (
                <div className={styles.showroomItem} key={`Showroom${showroom.id}`}>
                    <div className={styles.showroomName}>
                        {showroom.showroom_name}
                    </div>
                    <p className={styles.showroomAddress}>{showroom.address1}</p>
                    <p className={styles.showroomAddress}>{showroom.address2}</p>
                    <p className={styles.showroomAddress}>{showroom.address3}</p>

                    {!empty(showroom.maps_url) && (
                        <div className={styles.showroomActions}>
                            <a href={showroom.maps_url} target="_blank"
                               rel="noopener noreferrer">Open maps</a>
                        </div>
                    )}

                    <button className={styles.showroomSelectButton}
                            type="button"
                            onClick={() => {
                                if(!isHub){
                                    this.onSelectShowroom(showroom.id);
                                }else{
                                    this.onSelectHub(showroom.id);
                                }
                            }}
                    >
                        Select
                    </button>
                </div>
            );
        })
    };

    renderShowroomsByRegion = (showroomsByRegion, isHub=false) => {
        return showroomsByRegion.map(region => {
            return <React.Fragment key={`Region${region.id}`}>
                <h3 className={styles.showroomRegionName}>{region.region_name}</h3>
                <div className={styles.showroomList}>
                    {isHub && this.renderShowrooms(region.showrooms, true)}
                    {!isHub && this.renderShowrooms(region.showrooms)}
                </div>
            </React.Fragment>;
        })
    }

    renderShippingMethods = shipping_methods => {
        const {selected_shipping_method} = this.state;

        return shipping_methods.map(shipping_method => {
            return (
                <div
                    className={`${styles.shippingMethodItem} ${!empty(selected_shipping_method) && selected_shipping_method.code === shipping_method.code && selected_shipping_method.service === shipping_method.service ? styles.active : ''}`}
                    key={`ShippingMethod${shipping_method.code}${shipping_method.service}`}
                    onClick={() => {
                        this.setState({
                            selected_shipping_method: shipping_method
                        });
                    }}
                >
                    {!empty(selected_shipping_method) && selected_shipping_method.code === shipping_method.code && selected_shipping_method.service === shipping_method.service && (
                        <i className="fa fa-check-circle-o" />
                    )}
                    <div className={styles.shippingMethodName}>{shipping_method.name}</div>
                    <div className={styles.shippingMethodService}>{shipping_method.service}</div>
                    <div>
                        <span className={styles.shippingMethodPrice}>
                            {this.props.symbol} {number_format(shipping_method.cost, 0)}
                        </span><br/>
                        {!empty(shipping_method.etd) && `Etd: ${shipping_method.etd} days`}
                    </div>

                    {!empty(shipping_method.notes) && (
                        <div className={styles.shippingMethodNotes}>
                            Notes: {shipping_method.notes}
                        </div>
                    )}
                </div>
            );
        });
    };

    applyPromoCode = () => {
        const { promoCode } = this.state;
        if(empty(promoCode)){
            message.error('Promo code is required!');
            return;
        }

        this.showLoader();
        apiCall(
            CHECKOUT_PROMO_CODE,
            'post',
            {
                local_device_id: localStorage.getItem('local_device_id'),
                promo_code: this.state.promoCode,
                showroom_id: this.state.selectedShowroomId
            },
            res => {
                this.setState({
                    promo: res.data.promo,
                    total_purchase: res.data.total_purchase
                });
            },
            err => {
                ErrorHandler.handleGeneralError(err);
            },
            res => {
                this.hideLoader();
            }
        )
    };

    goToPayment = () => {
        const {
            shippingType,
            selectedUserAddressId,
            shipping_meta,
            shipping_methods,
            selected_shipping_method,
            selectedShowroomId,
            recipient_name,
            recipient_phone,
            recipient_zip,
            address_street,
            province_id,
            city_id,
            subdistrict_id,
            address_notes,
            guest_email,
            promo
        } = this.state;

        if(!this.props.authenticated){
            if(empty(guest_email)){
                message.error('Please input your email address');
                return;
            }
        }

        if(window.confirm('Are you sure want to go to payment?')){
            this.showLoader();
            apiCall(
                CHECKOUT_TRX,
                'post',
                {
                    local_device_id: localStorage.getItem('local_device_id'),
                    shipping_type: shippingType,
                    showroom_id: selectedShowroomId,

                    guest_email: guest_email,

                    user_address_id: selectedUserAddressId,
                    address_recipient_name: recipient_name,
                    address_recipient_phone: recipient_phone,
                    address_zip: recipient_zip,
                    address_street,
                    province_id,
                    city_id,
                    subdistrict_id,
                    address_notes,

                    shipping_courier: selected_shipping_method && selected_shipping_method.name,
                    shipping_service_name: selected_shipping_method && selected_shipping_method.code,
                    shipping_service_desc: selected_shipping_method && selected_shipping_method.description,
                    shipping_etd: selected_shipping_method && selected_shipping_method.etd,
                    shipping_cost: selected_shipping_method && selected_shipping_method.cost,
                    shipping_notes: selected_shipping_method && selected_shipping_method.notes,
                    promo_code: promo?.code
                },
                res => {
                    // localStorage.removeItem('local_device_id');
                    this.setState({
                        paymentForm: res.data.payment_data
                    }, () => {
                        this.paymentForm.current.submit();
                    });
                },
                err => {
                    ErrorHandler.handleGeneralError(err);
                },
                res => {
                    this.hideLoader();
                }
            );
        }
    };

    render() {
        const {onlineCart, symbol, authenticated} = this.props;
        const {
            page,
            pageLoading,
            loadingText,
            availabilityCheckFailed,
            availableOnline,
            availableOnStore,
            availableStoresByRegion,
            availableStoresByRegionOnline,

            shippingType,

            user_addresses,
            provinces,
            cities,
            subdistricts,

            showAddressForm,
            address_label,
            recipient_name,
            recipient_phone,
            recipient_zip,
            address_street,
            province_id,
            city_id,
            subdistrict_id,
            address_notes,

            shipping_methods,
            selected_shipping_method,

            paymentForm,

            promoCode,
            promo,
            total_purchase,

            cartItemsAvailability
        } = this.state;

        let total = 0;
        let totalMobile = 0;
        let shippingCost = 0;
        let shippingString = '';
        let totalDiscount = 0;
        let discountString = '';

        let showrooms = [];
        availableStoresByRegion.map(region => {
            showrooms = showrooms.concat(...region.showrooms);
            return region;
        });
        const selectedShowroom = showrooms.find(showroom => showroom.id === this.state.selectedShowroomId);

        let showrooms_online = [];
        availableStoresByRegionOnline.map(region => {
            showrooms_online = showrooms_online.concat(...region.showrooms);
            return region;
        });
        const selectedShowroomOnline = showrooms_online.find(showroom => showroom.id === this.state.selectedShowroomId);

        if(shippingType === SHIPPING_TYPE_ONLINE){
            if(!empty(selected_shipping_method)){
                shippingCost = parseInt(selected_shipping_method.cost);
                shippingString = selected_shipping_method.name + ' ' + selected_shipping_method.code.toUpperCase() + ' - ' + selected_shipping_method.service;
                if(!empty(selectedShowroomOnline)){
                    shippingString += `, from ${selectedShowroomOnline.showroom_name}`;
                }

                if(!empty(promo)){
                    if(promo.category === 'SHIPPING'){
                        let totalShippingDisc = 0;
                        const discPercent = parseFloat(promo.disc_percent);
                        const discNominal = parseFloat(promo.disc_nominal);
                        const discMax = parseFloat(promo.disc_max);

                        if(discPercent > 0){
                            totalShippingDisc = shippingCost * (discPercent / 100);
                        }

                        if(discNominal > 0){
                            totalShippingDisc += discNominal;
                        }

                        if(totalShippingDisc > discMax){
                            totalShippingDisc = discMax;
                        }

                        if(totalShippingDisc >= shippingCost){
                            totalShippingDisc = shippingCost;
                            // shippingCost = 0;
                        }else{
                            // shippingCost -= totalShippingDisc;
                        }

                        totalDiscount = totalShippingDisc;

                        discountString = `Promo (${promo?.code}) Discount`;
                    }
                }
            }
        }else{
            shippingString = 'Store Pickup';
            if(!empty(selectedShowroom)) {
                shippingString += `, from ${selectedShowroom.showroom_name}`;
            }
        }

        if(!empty(promo)){
            if(promo.category === 'PRODUCT'){
                const discPercent = parseFloat(promo.disc_percent);
                const discNominal = parseFloat(promo.disc_nominal);
                const discMax = parseFloat(promo.disc_max);

                if(discPercent > 0){
                    totalDiscount = total_purchase * (discPercent / 100);
                }

                if(discNominal > 0){
                    totalDiscount += discNominal;
                }

                if(totalDiscount > discMax){
                    totalDiscount = discMax;
                }

                if(totalDiscount >= total_purchase){
                    totalDiscount = total_purchase;
                }

                discountString = `Promo (${promo?.code}) Discount`;
            }
        }

        let newOnlineCartItems = onlineCart.cart_items;
        if(!empty(cartItemsAvailability)){
            newOnlineCartItems = newOnlineCartItems.filter(cartItem => {
                let exist = false;
                cartItemsAvailability.map(row => {
                    if(row.id === cartItem.id){
                        if(row.qty > 0 && cartItem.qty <= row.qty){
                            exist = true;
                        }
                    }
                    return row;
                });

                return exist;
            });
        }

        let showShowroomAvailabilityWarning = false;
        let allCartQty = 0;
        let showroomAvailableQty = 0;
        if(!empty(cartItemsAvailability)){
            allCartQty = cartItemsAvailability.length;

            cartItemsAvailability.map(row => {
                if(row.qty > 0){
                    showroomAvailableQty++;
                }else{
                    showShowroomAvailabilityWarning = true;
                }

                return row;
            });
        }

        return (
            <div>
                {/*SEO Support*/}
                <Helmet>
                    <title>Coconut Island | PT Warna Mardhika</title>
                    <meta name="description"
                          content="Multikart – Multipurpose eCommerce React Template is a multi-use React template. It is designed to go well with multi-purpose websites. Multikart Bootstrap 4 Template will help you run multiple businesses."/>
                </Helmet>
                {/*SEO Support End */}

                <Loader fullPage loading={pageLoading} text={loadingText}/>

                <h1 style={{
                    textAlign: 'center',
                    fontFamily: "'DM Sans', sans-serif",
                    marginTop: 50,
                    marginBottom: 50,
                    fontSize: '40px',
                    fontWeight: 700
                }}
                >Checkout</h1>

                {page === 'choose-shipping-type' && (
                    <div className={styles.chooseShippingTypeWrapper}>
                        {(availableOnline === null || availableOnStore === null) && (
                            <>
                                {!availabilityCheckFailed && (
                                    <span>Checking your items availability..</span>
                                )}

                                {availabilityCheckFailed && (
                                    <>
                                        <div style={{marginBottom: 30}}>Fail to load your items availability..</div>
                                        <button onClick={this.checkAvailability} className="btn btn-solid">Retry</button>
                                    </>
                                )}
                            </>
                        )}
                        {availableOnline !== null && availableOnStore !== null && (
                            <React.Fragment>
                                <h3 className={styles.sectionTitle}>How would you like to get your items?</h3>

                                <div className={styles.chooseShippingTypeActions}>
                                    {availableOnline && (
                                        <button onClick={() => {
                                            if (authenticated) {
                                                this.loadUserAddresses();
                                            }

                                            this.setState({
                                                shippingType: SHIPPING_TYPE_ONLINE,
                                                page: 'choose-address'
                                            });
                                        }} className="btn btn-solid">Online Delivery</button>
                                    )}

                                    {!empty(availableStoresByRegion) && (
                                        <button onClick={() => {
                                            this.setState({
                                                shippingType: SHIPPING_TYPE_PICKUP,
                                                page: 'choose-showroom'
                                            });
                                        }} className="btn btn-solid">Store Pickup</button>
                                    )}
                                </div>
                            </React.Fragment>
                        )}
                    </div>
                )}

                {page === 'choose-address' && (
                    <div className="container" style={{marginBottom: 50}}>
                        <div className="row">

                            {authenticated && (
                                <>
                                    <div className="col-md-6">
                                        <h3>Click an Address to Choose</h3>

                                        {empty(user_addresses) && (
                                            <span>No address saved yet, please add a new address</span>
                                        )}

                                        {!empty(user_addresses) && (
                                            <div className={styles.userAddressList}>
                                                {this.renderUserAddresses(user_addresses)}
                                            </div>
                                        )}

                                        {!showAddressForm && (
                                            <div style={{marginTop: 30}}>
                                                <button
                                                    className="btn btn-theme"
                                                    onClick={this.onAddNewAddressBtnClicked}
                                                >
                                                    Add New Address
                                                </button>
                                            </div>
                                        )}
                                    </div>

                                    {showAddressForm && (
                                        <div className="col-md-6">
                                            <h3>New Address</h3>

                                            <div className={styles.formItem}>
                                                <label>Address Label</label>
                                                <Input placeholder="E.g. My Home, My Office, etc.."
                                                       value={address_label}
                                                       onChange={this.onChangeAddressLabel}
                                                />
                                            </div>

                                            <div className={styles.formItem}>
                                                <label>Recipient Name</label>
                                                <Input placeholder="Name.."
                                                       value={recipient_name}
                                                       onChange={this.onChangeRecipientName}
                                                />
                                            </div>

                                            <div className={styles.formItem}>
                                                <label>Recipient Phone</label>
                                                <Input placeholder="Phone number.."
                                                       value={recipient_phone}
                                                       onChange={this.onChangeRecipientPhone}
                                                />
                                            </div>

                                            <div className={styles.formItem}>
                                                <label>Address Street</label>
                                                <Input.TextArea
                                                    placeholder="Street name & no.."
                                                    rows={4}
                                                    value={address_street}
                                                    onChange={this.onChangeAddressStreet}
                                                />
                                            </div>

                                            <div className={styles.formItem}>
                                                <label>Province</label>
                                                <br/>
                                                <Select
                                                    showSearch
                                                    style={{width: '100%'}}
                                                    placeholder="Select a province"
                                                    optionFilterProp="children"
                                                    onChange={this.onChangeProvince}
                                                    filterOption={(input, option) => {
                                                        return option.props?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                                    }
                                                    }
                                                >
                                                    {provinces.map(row => {
                                                        return (
                                                            <Select.Option value={row.id} key={`Province${row.id}`}>
                                                                {row.province_name}
                                                            </Select.Option>
                                                        );
                                                    })}
                                                </Select>
                                            </div>

                                            <div className={styles.formItem}>
                                                <label>City</label>
                                                <br/>
                                                <Select
                                                    disabled={empty(province_id)}
                                                    showSearch
                                                    style={{width: '100%'}}
                                                    placeholder="Select a city"
                                                    optionFilterProp="children"
                                                    onChange={this.onChangeCity}
                                                    filterOption={(input, option) =>
                                                        option.props?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    {cities.map(row => {
                                                        return (
                                                            <Select.Option value={row.id} key={`City${row.id}`}>
                                                                {row.type} {row.city_name}
                                                            </Select.Option>
                                                        );
                                                    })}
                                                </Select>
                                            </div>

                                            <div className={styles.formItem}>
                                                <label>Subdistrict</label>
                                                <br/>
                                                <Select
                                                    disabled={empty(city_id)}
                                                    showSearch
                                                    style={{width: '100%'}}
                                                    placeholder="Select a subdistrict"
                                                    optionFilterProp="children"
                                                    onChange={this.onChangeSubdistrict}
                                                    filterOption={(input, option) =>
                                                        option.props?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    {subdistricts.map(row => {
                                                        return (
                                                            <Select.Option value={row.id} key={`Subdistrict${row.id}`}>
                                                                {row.subdistrict_name}
                                                            </Select.Option>
                                                        );
                                                    })}
                                                </Select>
                                            </div>

                                            <div className={styles.formItem}>
                                                <label>Zipcode</label>
                                                <Input placeholder="Zipcode.."
                                                       value={recipient_zip}
                                                       onChange={this.onChangeRecipientZip}
                                                />
                                            </div>

                                            <div className={styles.formItem}>
                                                <label>Delivery Notes</label>
                                                <Input.TextArea
                                                    placeholder="Your address / delivery notes.."
                                                    rows={4}
                                                    value={address_notes}
                                                    onChange={this.onChangeAddressNotes}
                                                />
                                            </div>

                                            <div className={styles.formItem}>
                                                <button
                                                    onClick={this.onSubmitNewAddress}
                                                    className="btn btn-theme"
                                                    style={{marginRight: 20}}
                                                >
                                                    Save This Address
                                                </button>

                                                <button
                                                    onClick={this.onCancelNewAddressForm}
                                                    className="btn btn-classic"
                                                    style={{marginRight: 20}}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}

                            {!authenticated && (
                                <div className="col-md-6">
                                    <h3>Delivery Address</h3>

                                    <div className={styles.formItem}>
                                        <label>Recipient Name</label>
                                        <Input placeholder="Name.."
                                               value={recipient_name}
                                               onChange={this.onChangeRecipientName}
                                        />
                                    </div>

                                    <div className={styles.formItem}>
                                        <label>Recipient Phone</label>
                                        <Input placeholder="Phone number.."
                                               value={recipient_phone}
                                               onChange={this.onChangeRecipientPhone}
                                        />
                                    </div>

                                    <div className={styles.formItem}>
                                        <label>Address Street</label>
                                        <Input.TextArea
                                            placeholder="Street name & no.."
                                            rows={4}
                                            value={address_street}
                                            onChange={this.onChangeAddressStreet}
                                        />
                                    </div>

                                    <div className={styles.formItem}>
                                        <label>Province</label>
                                        <br/>
                                        <Select
                                            showSearch
                                            style={{width: '100%'}}
                                            placeholder="Select a province"
                                            optionFilterProp="children"
                                            onChange={this.onChangeProvince}
                                            filterOption={(input, option) =>
                                                option.props?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {provinces.map(row => {
                                                return (
                                                    <Select.Option value={row.id} key={`Province${row.id}`}>
                                                        {row.province_name}
                                                    </Select.Option>
                                                );
                                            })}
                                        </Select>
                                    </div>

                                    <div className={styles.formItem}>
                                        <label>City</label>
                                        <br/>
                                        <Select
                                            disabled={empty(province_id)}
                                            showSearch
                                            style={{width: '100%'}}
                                            placeholder="Select a city"
                                            optionFilterProp="children"
                                            onChange={this.onChangeCity}
                                            filterOption={(input, option) =>
                                                option.props?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {cities.map(row => {
                                                return (
                                                    <Select.Option value={row.id} key={`City${row.id}`}>
                                                        {row.type} {row.city_name}
                                                    </Select.Option>
                                                );
                                            })}
                                        </Select>
                                    </div>

                                    <div className={styles.formItem}>
                                        <label>Subdistrict</label>
                                        <br/>
                                        <Select
                                            disabled={empty(city_id)}
                                            showSearch
                                            style={{width: '100%'}}
                                            placeholder="Select a subdistrict"
                                            optionFilterProp="children"
                                            onChange={this.onChangeSubdistrict}
                                            filterOption={(input, option) =>
                                                option.props?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {subdistricts.map(row => {
                                                return (
                                                    <Select.Option value={row.id} key={`Subdistrict${row.id}`}>
                                                        {row.subdistrict_name}
                                                    </Select.Option>
                                                );
                                            })}
                                        </Select>
                                    </div>

                                    <div className={styles.formItem}>
                                        <label>Delivery Notes</label>
                                        <Input.TextArea
                                            placeholder="Your address / delivery notes.."
                                            rows={4}
                                            value={address_notes}
                                            onChange={this.onChangeAddressNotes}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <div className={styles.chooseAddressActions}>
                                    <button
                                        onClick={() => {
                                            this.setState({
                                                page: 'choose-shipping-type'
                                            });
                                        }}
                                        className="btn btn-solid"
                                    >
                                        Back
                                    </button>

                                    <button
                                        onClick={this.onContinueFromChooseAddress}
                                        className="btn btn-solid"
                                    >
                                        Continue
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {page === 'choose-hub' && (
                    <div className="container" style={{marginBottom: 50}}>
                        <div className="row">
                            <div className="col-md-12">
                                <h3 className={styles.sectionTitle}>Choose where your item will be sent from</h3>

                                {this.renderShowroomsByRegion(availableStoresByRegionOnline, true)}

                                <div className={styles.showroomListActions}>
                                    <button className="btn btn-solid"
                                            onClick={() => {
                                                this.setState({
                                                    page: 'choose-address'
                                                });
                                            }}
                                    >
                                        Back
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {page === 'choose-shipping-method' && (
                    <div className="container" style={{marginBottom: 50}}>
                        <div className="row">
                            <div className="col-md-12">
                                <h3 className={styles.sectionTitle}>Choose your shipping courier</h3>

                                {empty(shipping_methods) && (
                                    <div style={{textAlign: 'center', margin: 30}}>Loading shipping methods..</div>
                                )}

                                {!empty(shipping_methods) && (
                                    <div className={styles.shippingMethodList}>
                                        {this.renderShippingMethods(shipping_methods)}
                                    </div>
                                )}

                                <div className={styles.showroomListActions}>
                                    <button className="btn btn-solid"
                                            onClick={() => {
                                                this.setState({
                                                    page: 'choose-address',
                                                    ...this.emptyShippingMethod
                                                });
                                            }}
                                    >
                                        Back
                                    </button>

                                    <button
                                        onClick={this.onContinueFromChooseShippingMethod}
                                        className="btn btn-solid"
                                    >
                                        Continue
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {page === 'choose-showroom' && (
                    <div className="container" style={{marginBottom: 50}}>
                        <div className="row">
                            <div className="col-md-12">
                                <h3 className={styles.sectionTitle}>Where would you like to pickup your items?</h3>

                                {this.renderShowroomsByRegion(availableStoresByRegion)}

                                <div className={styles.showroomListActions}>
                                    <button className="btn btn-solid"
                                            onClick={() => {
                                                this.setState({
                                                    page: 'choose-shipping-type'
                                                });
                                            }}
                                    >
                                        Back
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {page === 'order-summary' && (
                    <div className="container" style={{marginBottom: 50}}>
                        <div className="row">
                            <div className="col-md-12">
                                <h3 style={{textAlign: 'center'}}>Order Summary</h3>

                                {showShowroomAvailabilityWarning && (
                                    <div style={{
                                        backgroundColor: RED,
                                        padding: '10px 20px',
                                        color: 'white',
                                        margin: '30px 0 0 0',
                                        borderRadius: 4
                                    }}>
                                        {allCartQty - showroomAvailableQty} item(s) were excluded from your cart because they are not available in your chosen store.<br/>If you wish to buy them too, please choose another store in the next checkout after this one is finished.
                                    </div>
                                )}

                                {!empty(onlineCart) && !empty(newOnlineCartItems) && (
                                    <section className="cart-section section-b-space">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <h3>Items ({newOnlineCartItems.length})</h3>
                                                    <hr/>
                                                    <table className={`table cart-table table-responsive-xs ${styles.cartTable}`}>
                                                        <thead>
                                                        <tr className="table-head">
                                                            <th scope="col">&nbsp;</th>
                                                            <th scope="col">product name</th>
                                                            <th scope="col">Size</th>
                                                            <th scope="col">price</th>
                                                            <th scope="col">quantity</th>
                                                            <th scope="col">total</th>
                                                        </tr>
                                                        </thead>
                                                        {newOnlineCartItems.map((item, index) => {
                                                            const retailPrice = parseInt(item.product_detail.retail_price);
                                                            const netPrice = parseInt(item.product_detail.net_price);
                                                            // const directDiscPercent = !empty(item.product_detail.direct_discount)?parseFloat(item.product_detail.direct_discount.direct_disc_percent):0;
                                                            // const directDiscNominal = !empty(item.product_detail.direct_discount)?parseInt(item.product_detail.direct_discount.direct_disc_nominal):0;
                                                            // const grandPrice = (netPrice * ((100-directDiscPercent)/100)).toFixed(0) - directDiscNominal;
                                                            const grandPrice = netPrice;

                                                            total += grandPrice * item.qty;

                                                            return (
                                                                <tbody key={index}>
                                                                <tr>
                                                                    <td>
                                                                        <Link
                                                                            to={`${process.env.PUBLIC_URL}/left-sidebar/product/${item.id}`}>
                                                                            <img src={item.product_detail.product_images[0].image_url} alt=""/>
                                                                        </Link>
                                                                    </td>
                                                                    <td><Link
                                                                        to={`${process.env.PUBLIC_URL}/left-sidebar/product/${item.id}`}>{item.product_detail.product_name}</Link>
                                                                        <div className="mobile-cart-content row">
                                                                            <div className="col-xs-3">
                                                                                <div className="qty-box">
                                                                                    <div className="input-group">
                                                                                        <input type="text" name="quantity"
                                                                                               className="form-control input-number"
                                                                                               defaultValue={item.qty}/>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-xs-3">
                                                                                <h2 className="td-color">{symbol} {number_format(grandPrice, 0)}</h2>
                                                                            </div>
                                                                            <div className="col-xs-3">
                                                                                <h2 className="td-color">
                                                                                    <a href="#" className="icon"
                                                                                       onClick={() => this.props.removeFromCart(item)}>
                                                                                        <i className="icon-close"/>
                                                                                    </a>
                                                                                </h2>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td><h2 style={{
                                                                        fontSize: 16
                                                                    }}
                                                                    >{item.size_name}</h2></td>

                                                                    <td>
                                                                        <h2 style={{
                                                                            fontSize: 16
                                                                        }}
                                                                        >
                                                                            {retailPrice !== grandPrice && (
                                                                                <div style={{marginBottom: 5}}>
                                                                                    <del><span className="money">{symbol} {number_format(retailPrice, 0)}</span></del>
                                                                                </div>
                                                                            )}
                                                                            {symbol} {number_format(grandPrice, 0)}
                                                                        </h2>
                                                                    </td>

                                                                    <td>
                                                                        <div className="qty-box">
                                                                            {item.qty}
                                                                        </div>
                                                                    </td>
                                                                    <td><h2 style={{
                                                                        fontSize: 16,
                                                                        color: '#222',
                                                                        fontWeight: 700
                                                                    }}
                                                                            className="td-color">{symbol} {number_format(grandPrice * item.qty, 0)}</h2>
                                                                    </td>
                                                                </tr>
                                                                </tbody>)
                                                        })}
                                                    </table>

                                                    <table className={styles.cartTableMobile}>
                                                        <tbody>
                                                        {newOnlineCartItems.map((item, index) => {
                                                            const retailPrice = parseInt(item.product_detail.retail_price);
                                                            const netPrice = parseInt(item.product_detail.net_price);
                                                            const grandPrice = netPrice;

                                                            totalMobile += grandPrice * item.qty;

                                                            return (
                                                                <React.Fragment key={index}>
                                                                    <tr className={styles.mobileCartItem}>
                                                                        <td colSpan={7}>
                                                                            <div className={styles.productImage}>
                                                                                <Link
                                                                                    to={`${process.env.PUBLIC_URL}/left-sidebar/product/${item.id}`}>
                                                                                    <img src={item.product_detail.product_images[0].image_url} alt=""/>
                                                                                </Link>
                                                                            </div>

                                                                            <div className={styles.productRight}>
                                                                                <Link
                                                                                    className={styles.productName}
                                                                                    to={`${process.env.PUBLIC_URL}/left-sidebar/product/${item.id}`}>{item.product_detail.product_name}</Link>

                                                                                <h2 className={styles.productSize}
                                                                                >Size: {item.size_name}</h2>

                                                                                <h2 className={styles.productSize}
                                                                                >Qty: {item.qty}</h2>

                                                                                <h2 className={styles.productPrice}
                                                                                >
                                                                                    {retailPrice !== grandPrice && (
                                                                                        <div style={{marginBottom: 5}}>
                                                                                            <del><span className="money">{symbol} {number_format(retailPrice, 0)}</span></del>
                                                                                        </div>
                                                                                    )}
                                                                                    {symbol} {number_format(grandPrice, 0)}
                                                                                </h2>

                                                                            </div>

                                                                            <div className={styles.productOptions}>
                                                                                {/*<div className={`qty-box ${styles.productQty}`}>
                                                                                    x{item.qty}
                                                                                </div>*/}

                                                                                <h2 className={styles.productGrandPrice}>{symbol} {number_format(grandPrice * item.qty, 0)}
                                                                                </h2>

                                                                                <div className={styles.removeBtn}>
                                                                                    <a href="#" className="icon"
                                                                                       onClick={() => this.removeItem(item)}>
                                                                                        {/*<i className="fa fa-times"/>&nbsp;*/}
                                                                                        remove
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </React.Fragment>
                                                            );
                                                        })}
                                                        </tbody>
                                                    </table>

                                                    <table className={`table cart-table table-responsive-md ${styles.footerTable}`}>
                                                        <tfoot>
                                                        <tr>
                                                            <td>
                                                                Shipping Cost ({shippingString})
                                                                {/*{!empty(shippingString2) && (
                                                                    <>
                                                                        <br/>
                                                                        {shippingString2}
                                                                    </>
                                                                )}*/}
                                                            </td>
                                                            <td>
                                                                {symbol} {number_format(shippingCost, 0)}
                                                            </td>
                                                        </tr>

                                                        {!empty(discountString) && (
                                                            <tr>
                                                                <td>
                                                                    {discountString}
                                                                </td>
                                                                <td>
                                                                    - {symbol} {number_format(totalDiscount, 0)}
                                                                </td>
                                                            </tr>
                                                        )}

                                                        <tr>
                                                            <td>Grand Total</td>
                                                            <td>
                                                                <h2>{symbol} {number_format(total - totalDiscount + shippingCost, 0)} </h2>
                                                            </td>
                                                        </tr>
                                                        </tfoot>
                                                    </table>
                                                </div>

                                            </div>


                                            {empty(promo) && (
                                                <>
                                                    <hr style={{marginTop: 35}}/>

                                                    <div className={styles.promoCode}>
                                                        <div className={styles.formItem}>
                                                            <label>Have a promo code?</label>
                                                            <br/>
                                                            <Input placeholder="Promo code.."
                                                                   value={promoCode}
                                                                   onChange={e => {
                                                                       this.setState({
                                                                           promoCode: e.target.value
                                                                       });
                                                                   }}
                                                            />
                                                            <button onClick={this.applyPromoCode}
                                                                    className="btn btn-solid">Apply</button>
                                                        </div>
                                                    </div>
                                                </>
                                            )}

                                            <hr style={{marginTop: 35, marginBottom: 35}}/>

                                            <div className={styles.paymentArea}>
                                                <button onClick={this.goToPayment}
                                                        className="btn btn-solid">Go To Payment</button>
                                            </div>

                                            {!empty(paymentForm) && (
                                                <div style={{display:'none'}}>
                                                    <form method="post" action={FASPAY_XPRESS_URL} ref={this.paymentForm}>
                                                        <input type="hidden" name="merchant_id" value={paymentForm.merchant_id} />
                                                        <input type="hidden" name="merchant_name" value={paymentForm.merchant_name} />
                                                        <input type="hidden" name="order_id" value={paymentForm.order_id} />
                                                        <input type="hidden" name="order_reff" value={paymentForm.order_reff} />
                                                        <input type="hidden" name="bill_date" value={paymentForm.bill_date} />
                                                        <input type="hidden" name="bill_expired" value={paymentForm.bill_expired} />
                                                        <input type="hidden" name="bill_desc" value={paymentForm.bill_desc} />
                                                        <input type="hidden" name="bill_total" value={paymentForm.bill_total} />
                                                        <input type="hidden" name="return_url" value={paymentForm.return_url} />
                                                        <input type="hidden" name="display_cust" value="true" />
                                                        <input type="hidden" name="custNo" value={paymentForm.custNo} />
                                                        <input type="hidden" name="custName" value={paymentForm.custName} />
                                                        {/*<input type="hidden" name="custPhone" value={paymentForm.custPhone} />*/}
                                                        <input type="hidden" name="custEmail" value={paymentForm.custEmail} />
                                                        <input type="hidden" name="products" value={paymentForm.products} />
                                                        {/*<input type="hidden" name="signature" value={SHA1(MD5('bot33125'+'p@ssw0rd'+'a3'+'159000').toString())} />*/}
                                                        <input type="hidden" name="signature" value={paymentForm.signature} />
                                                        <input type="hidden" name="term_condition" value="1" />
                                                        {/*<button type="submit">Pay</button>*/}
                                                    </form>
                                                </div>
                                            )}
                                            {/*<div className="row cart-buttons">
                                                <div className="col-6">
                                                    <Link to={`${process.env.PUBLIC_URL}/left-sidebar/collection`}
                                                          className="btn btn-solid">continue shopping</Link>
                                                </div>
                                                <div className="col-6">
                                                    {!this.props.authenticated && (
                                                        <>
                                                            <Link style={{margin: 10}} to={`${process.env.PUBLIC_URL}/sign-in`} className="btn btn-solid">Sign In</Link>
                                                            <Link style={{margin: 10}} to={`${process.env.PUBLIC_URL}/checkout`} className="btn btn-solid">Checkout Without Sign In</Link>
                                                        </>
                                                    )}

                                                    {this.props.authenticated && (
                                                        <Link style={{margin: 10}} to={`${process.env.PUBLIC_URL}/checkout`} className="btn btn-solid">Checkout</Link>
                                                    )}
                                                </div>
                                            </div>*/}
                                        </div>
                                    </section>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    onlineCart: state.cartList.onlineCart,
    cartItems: state.cartList.cart,
    symbol: state.data.symbol,
    total: getCartTotal(state.cartList.cart),
    authenticated: state.auth.authenticated
});

export default connect(
    mapStateToProps,
    {removeFromCart, incrementQty, decrementQty, retrieveCart}
)(Checkout)