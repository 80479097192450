import {empty} from "../helpers/generalHelper";
import uuid from "uuid/v4";

export const APP_VERSION = '1.1.1';
export const BUILD_NUMBER = 2;
export const BRAND_CODE = 'CI';

export const API_BASE_URL = 'https://api.warna-mardhika.com/'; // (window.location.host === 'coconutisland75.com' || window.location.host === 'www.coconutisland75.com') ? 'https://api.warna-mardhika.com/' : (window.location.host === 'ecom.warna-mardhika.com' ? 'https://dev-api.coconutisland75.com/' : 'http://wm-ecommerce-api.test/');
export const FASPAY_XPRESS_URL = (window.location.host === 'coconutisland75.com' || window.location.host === 'www.coconutisland75.com') ? 'https://xpress.faspay.co.id/v3/payment' : (window.location.host === 'ecom.warna-mardhika.com' ? 'https://dev.faspay.co.id/xpress/payment' : 'https://dev.faspay.co.id/xpress/payment');
export const IMAGE_BASE_URL = 'https://office.pemudateochewindonesia.com/';
export const MONTH_NAMES_LONG = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
];
export const MONTH_NAMES_SHORT = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
];
export const MOBILE_DATEPICKER_MONTH_LONG_MAP = {
    '01': 'January',
    '02': 'February',
    '03': 'March',
    '04': 'April',
    '05': 'May',
    '06': 'June',
    '07': 'July',
    '08': 'August',
    '09': 'September',
    '10': 'October',
    '11': 'November',
    '12': 'December'
};

export const MOMENT_READABLE_DATE_FORMAT = 'D MMM YYYY';
export const MOMENT_READABLE_DATETIME_FORMAT = 'D MMM YYYY, HH:mm';
export const MOMENT_SQL_DATE_FORMAT = 'YYYY-MM-DD';
export const MOMENT_SQL_DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export const PROMO_TYPE_GROUP = "GROUP";
export const PROMO_TYPE_SUB_GROUP = "SUB_GROUP";
export const PROMO_TYPE_PRODUCT = "PRODUCT";

export const SHIPPING_TYPE_ONLINE = "ONLINE_DELIVERY";
export const SHIPPING_TYPE_PICKUP = "PICKUP_ON_STORE";

export const LOCAL_DEVICE_ID = () => {
    let local_device_id = localStorage.getItem('local_device_id');
    if(empty(local_device_id)){
        local_device_id = uuid();
        localStorage.setItem('local_device_id', local_device_id);
    }

    return local_device_id;
};