import React, {Component} from 'react';
import {Helmet} from "react-helmet";
import styles from './StoresOnlineDelivery.module.scss';
import {empty} from "../helpers/generalHelper";
import ModuleNewsletter from "./ModuleNewsletter";

class StoresOnlineDelivery extends Component {
    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>Coconut Island | PT Warna Mardhika</title>
                    <meta name="description"
                          content="Inspired by an Island in Rio de Janeiro, Brazil. COCONUT ISLAND rapidly developing into a premium brand t-shirt in Indonesia."/>
                </Helmet>

                <section className={styles.coverSection}>
                    <img src={`${process.env.PUBLIC_URL}/assets/images/wm/banner-onlinedelivery.jpg`} />
                </section>

                <section className={styles.titleSection}>
                    <h1 className={styles.title}>Online Delivery</h1>
                </section>

                <section className={styles.stepSection}>
                    <div className={styles.stepWord}>STEP</div>
                    <div className={styles.stepItems}>
                        <div className={styles.stepItem}>
                            <div className={styles.number}>
                                1
                            </div>
                            <div className={styles.info}>
                                <div className={styles.title}>
                                    Checkout
                                </div>
                                <div className={styles.desc}>
                                    Masukan produk favoritmu ke dalam keranjang lalu checkout<br/>
                                    Sign In to process. Apabila kamu belum mempunyai akun silahkan untuk Sign Up terlebih dahulu<br/>
                                    Lalu klik Checkout
                                </div>
                            </div>
                        </div>

                        <div className={styles.stepItem}>
                            <div className={styles.number}>
                                2
                            </div>
                            <div className={styles.info}>
                                <div className={styles.title}>
                                    Shipping Address
                                </div>
                                <div className={styles.desc}>
                                    Pilih Online Delivery<br/>
                                    Lalu masukan alamat tujuan pengiriman dengan klik Add New Adress<br/>
                                    Isi lalu save this address<br/>
                                    Klik Alamat yang telah di save hingga muncul icon v<br/>
                                    Continue
                                </div>
                            </div>
                        </div>

                        <div className={styles.stepItem}>
                            <div className={styles.number}>
                                3
                            </div>
                            <div className={styles.info}>
                                <div className={styles.title}>
                                    Shipping Courier
                                </div>
                                <div className={styles.desc}>
                                    Pilih toko Coconut Island yang paling dekat dengan rumahmu<br/>
                                    Klik Select<br/>
                                    Lalu pilih kurir yang akan mengantar paketmu<br/>
                                    Checkout dan bayar pesananmu melalui berbagai chanel pembayaran
                                </div>
                            </div>
                        </div>

                        <div className={styles.stepItem}>
                            <div className={styles.number}>
                                4
                            </div>
                            <div className={styles.info}>
                                <div className={styles.title}>
                                    Package Delivery
                                </div>
                                <div className={styles.desc}>
                                    Setelah selesai melakukan pembayaran kamu akan mendapatkan tanda terima dan nomor resi melalui email atau dengan cek menu My Order di Profil Account<br/>
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/wm/howto-onlinedelivery-1.jpg`} /> <br/>
                                    Paketmu akan segera di proses oleh admin kami dalam 1 x 24 jam<br/>
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/wm/howto-onlinedelivery-2.jpg`} /> <br/>
                                    Setelah paket dikemas dengan aman, kurir akan mengantar paket ke rumahmu.<br/>
                                    Kamu juga bisa tracking order melalui menu My orders<br/>
                                    <br/>
                                    Selamat berbelanja
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <ModuleNewsletter />

            </React.Fragment>
        );
    }
}

export default StoresOnlineDelivery;