import React, {Component} from 'react';
import {Helmet} from "react-helmet";
import styles from './Hashtag.module.scss';
import FooterOne from "./common/footers/footer-one";
import {svgFreeShipping, svgoffer, svgservice} from "../services/script";
import ModuleNewsletter from "./ModuleNewsletter";

class Hashtag extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        setTimeout(function () {
            document.querySelector(".loader-wrapper").style = "display: none";
        }, 2000);
    }

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>Coconut Island | PT Warna Mardhika</title>
                    <meta name="description"
                          content="Multikart – Multipurpose eCommerce React Template is a multi-use React template. It is designed to go well with multi-purpose websites. Multikart Bootstrap 4 Template will help you run multiple businesses."/>
                </Helmet>

                <section className={styles.hashtagSection}>
                    <h2>#iamCOCONUTs</h2>
                    <p>
                        Share your personal style and see how others are styling their favourites from Coconut Island.<br/>Tag
                        your picture with @coconutislandclothing.id and #iamCOCONUTs for a chance to be featured on coconutisland75.com
                        and in our stores.
                    </p>
                    <div className={styles.hashtagPhotos}>
                        <div className={styles.hashtagPhoto}>
                            <a href="https://www.instagram.com/coconutislandclothing.id" target="_blank" rel="noopener noreferrer">
                                <img src={`${process.env.PUBLIC_URL}/assets/images/wm/ci/hashtag-1.jpeg`}
                                     alt="HashTag Photo"/>
                                <div className={styles.hashtagPhotoOverlay}>
                                    <div className={styles.hashtagPhotoText}>
                                        @instagram_account
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className={styles.hashtagPhoto}>
                            <a href="https://www.instagram.com/coconutislandclothing.id" target="_blank" rel="noopener noreferrer">
                                <img src={`${process.env.PUBLIC_URL}/assets/images/wm/ci/hashtag-2.jpg`}
                                     alt="HashTag Photo"/>
                                <div className={styles.hashtagPhotoOverlay}>
                                    <div className={styles.hashtagPhotoText}>
                                        @instagram_account
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className={styles.hashtagPhoto}>
                            <a href="https://www.instagram.com/coconutislandclothing.id" target="_blank" rel="noopener noreferrer">
                                <img src={`${process.env.PUBLIC_URL}/assets/images/wm/ci/hashtag-3.jpg`}
                                     alt="HashTag Photo"/>
                                <div className={styles.hashtagPhotoOverlay}>
                                    <div className={styles.hashtagPhotoText}>
                                        @instagram_account
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className={styles.hashtagPhoto}>
                            <a href="https://www.instagram.com/coconutislandclothing.id" target="_blank" rel="noopener noreferrer">
                                <img src={`${process.env.PUBLIC_URL}/assets/images/wm/ci/hashtag-4.jpg`}
                                     alt="HashTag Photo"/>
                                <div className={styles.hashtagPhotoOverlay}>
                                    <div className={styles.hashtagPhotoText}>
                                        @instagram_account
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className={styles.hashtagPhoto}>
                            <a href="https://www.instagram.com/coconutislandclothing.id" target="_blank" rel="noopener noreferrer">
                                <img src={`${process.env.PUBLIC_URL}/assets/images/wm/ci/hashtag-5.jpg`}
                                     alt="HashTag Photo"/>
                                <div className={styles.hashtagPhotoOverlay}>
                                    <div className={styles.hashtagPhotoText}>
                                        @instagram_account
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className={styles.hashtagPhoto}>
                            <a href="https://www.instagram.com/coconutislandclothing.id" target="_blank" rel="noopener noreferrer">
                                <img src={`${process.env.PUBLIC_URL}/assets/images/wm/ci/hashtag-6.jpg`}
                                     alt="HashTag Photo"/>
                                <div className={styles.hashtagPhotoOverlay}>
                                    <div className={styles.hashtagPhotoText}>
                                        @instagram_account
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className={styles.hashtagPhoto}>
                            <a href="https://www.instagram.com/coconutislandclothing.id" target="_blank" rel="noopener noreferrer">
                                <img src={`${process.env.PUBLIC_URL}/assets/images/wm/ci/hashtag-7.jpg`}
                                     alt="HashTag Photo"/>
                                <div className={styles.hashtagPhotoOverlay}>
                                    <div className={styles.hashtagPhotoText}>
                                        @instagram_account
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className={styles.hashtagPhoto}>
                            <a href="https://www.instagram.com/coconutislandclothing.id" target="_blank" rel="noopener noreferrer">
                                <img src={`${process.env.PUBLIC_URL}/assets/images/wm/ci/hashtag-8.jpg`}
                                     alt="HashTag Photo"/>
                                <div className={styles.hashtagPhotoOverlay}>
                                    <div className={styles.hashtagPhotoText}>
                                        @instagram_account
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className={styles.hashtagPhoto}>
                            <a href="https://www.instagram.com/coconutislandclothing.id" target="_blank" rel="noopener noreferrer">
                                <img src={`${process.env.PUBLIC_URL}/assets/images/wm/ci/hashtag-9.jpg`}
                                     alt="HashTag Photo"/>
                                <div className={styles.hashtagPhotoOverlay}>
                                    <div className={styles.hashtagPhotoText}>
                                        @instagram_account
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </section>

                <ModuleNewsletter />

            </React.Fragment>
        );
    }
}

export default Hashtag;